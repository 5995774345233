<template>
  <div class="rankingbox">
    <div class="cheangebox">
      <div
        class="changeitem point"
        v-for="item in typelist"
        @click="changetype(item.id)"
        :class="{ acchangeitem: item.id == acindex }"
        :key="item.id"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="center">
      <div class="ranktitle">
        <p v-show="this.acindex == 1">群雄逐鹿</p>
        <p v-show="this.acindex == 2">天下英豪</p>
        <p v-show="this.acindex == 3">问鼎天下</p>
      </div>

      <div
        class="timebox"
        v-if="
          this.showtype.days_open == '1' &&
          this.showtype.weeks_open == '1' &&
          (this.acindex == 1 || this.acindex == 3)
        "
      >
        <p class="timetext">排行榜倒计时：</p>
        <el-statistic
          class="timenum"
          format="DD天HH小时mm分钟ss秒"
          :value="drawTime"
          time-indices
        >
        </el-statistic>
      </div>
      <div class="timebox" v-else>
        <p class="timetext">排行榜已截止</p>
      </div>
      <div class="contentbox">
        <div class="tipsbox point" @click="showtips">
          <img src="../../assets/images/ranking/tipsicon.png" alt="" />
          <p>玩法说明</p>
          <div class="tipstextbox" v-show="tipsshow">
            当日开箱最欧皇用户将获得惊喜彩蛋福利（次日0:00发放）最大欧皇将获得最大惊喜彩蛋！（积分开箱、福利宝箱除外）活动最终解释权，归TM方所有
          </div>
        </div>
        <div class="sort-box-top">
          <div class="img-list">
            <div class="img-item img-item2">
              <div class="header-img header-img2">
                <img
                  class="img1"
                  src="../../assets/images/ranking/No2.png"
                  alt=""
                />
                <img class="img2" :src="No2.user?.avatar" alt="" />
              </div>
              <div class="header-name">
                {{ No2.user?.name || "虚位以待" }}
                <div class="block4">
                  <Hotprice
                    :size="0.18"
                    :isWeight="false"
                    :price="No2?.win || No2?.total_win"
                  />
                </div>
              </div>
            </div>
            <div class="img-item img-item1">
              <div class="header-img header-img1">
                <img
                  class="img1"
                  src="../../assets/images/ranking/No1.png"
                  alt=""
                />
                <img class="img2" :src="No1.user?.avatar" alt="" />
              </div>
              <div class="header-name">
                {{ No1.user?.name || "虚位以待" }}
                <div class="block4">
                  <Hotprice
                    :size="0.18"
                    :isWeight="false"
                    :price="No1?.win || No1?.total_win"
                  />
                </div>
              </div>
            </div>
            <div class="img-item img-item3">
              <div class="header-img header-img3">
                <img
                  class="img1"
                  src="../../assets/images/ranking/No3.png"
                  alt=""
                />
                <img class="img2" :src="No3.user?.avatar" alt="" />
              </div>
              <div class="header-name">
                {{ No3.user?.name || "虚位以待" }}
                <div class="block4">
                  <Hotprice
                    :size="0.18"
                    :isWeight="false"
                    :price="No3?.win || No3?.total_win"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sort_box_bottom"></div>
      </div>
      <div class="infolist">
        <div class="userlist">
          <div class="flex">
            当前你的火力值
            <span class="numcolor"
              ><Hotprice
                :size="0.18"
                :isWeight="false"
                :price="this.userinfo?.win || this.userinfo?.total_win"
            /></span>
          </div>
          <div class="top">
            排名 <span class="numcolor">{{ userIndex + 1 }}</span>
          </div>
          <div class="flex">
            距离前一名还差
            <span class="numcolor"
              ><Hotprice
                :size="0.18"
                :isWeight="false"
                :price="comparisonResult"
            /></span>
            火力值
          </div>
        </div>
        <div class="refsbox">
          <div class="refs point" @click="updata">
            <img
              src="../../assets/images/kaijiang/reficon.png"
              :class="restStatus ? 'restAni' : ''"
              alt=""
            />
            <div class="reftext">获取最新数据</div>
          </div>
        </div>
      </div>
      <div class="elselist">
        <div v-if="elselist.length">
          <div
            class="listitem"
            v-for="(item, index) in elselist"
            :class="[(index * 1 + 1) % 2 == 0 ? 'e1' : 'e2']"
            :key="index"
          >
            <div class="numbox">
              {{ index + 4 }}
            </div>
            <div class="userimgbox">
              <img :src="item.user.avatar" alt="" />
            </div>
            <div class="ename">{{ item.user.name }}</div>
            <div class="userjoinnum">
              <Hotprice
                :size="0.18"
                :isWeight="false"
                :price="item.win || item.total_win"
              />
            </div>
          </div>
        </div>
        <div class="nulldata" v-else>
          <div class="imgbox">
            <img src="@/assets/images/NewUser/pc-newuser-nulldata.png" alt="" />
            <div class="nulltext">暂无内容</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { winRanking } from "../../network/api";
import { mapState } from "vuex";
export default {
  data() {
    return {
      typelist: [
        { id: 1, title: "今日排行" },
        { id: 2, title: "昨日排行" },
        { id: 3, title: "总排行榜" },
      ],
      acindex: 1, //默认选中今日
      // 排行
      getRankingTopWTSList: [],
      userinfo: {},
      //其余排行
      elselist: [],
      showtype: "",
      restStatus: false,
      tipsshow: false,
      drawTime: null,
    };
  },
  created() {
    this.getRanking();
  },
  methods: {
    showtips() {
      this.tipsshow = !this.tipsshow;
    },
    changetype(v) {
      this.acindex = v;
      this.getRanking();
    },
    updata() {
      this.restStatus = true;
      this.getRanking();
      setTimeout(() => {
        this.restStatus = false;
      }, 1000);
    },
    // 获取排行榜
    async getRanking() {
      let params = {
        type: this.acindex,
      };
      let king = await winRanking(params);
      this.showtype = king.data.data.config;
      if (this.acindex == 3) {
        this.drawTime = new Date(this.showtype.weeks_end_time).getTime();
      } else {
        this.drawTime = new Date(this.showtype.day_end_time).getTime();
      }
      this.userinfo = king.data.data.user;
      this.getRankingTopWTSList = king.data.data.data;

      this.elselist = this.getRankingTopWTSList.slice(3);
    },
  },
  computed: {
    ...mapState(["user"]),
    renderList() {
      return this.getRankingTopWTSList;
    },
    No1() {
      return this.renderList[0] || { user: {} };
    },
    No2() {
      return this.renderList[1] || { user: {} };
    },
    No3() {
      return this.renderList[2] || { user: {} };
    },
    userIndex() {
      return this.getRankingTopWTSList.findIndex(
        (user) => user.user_id == this.user.id
      );
    },
    // 获取上一名
    previousUserId() {
      const index = this.userIndex;
      return index > 0 ? this.getRankingTopWTSList[index - 1].user_id : null;
    },
    // 获取上一名数据
    previousUserData() {
      const index = this.userIndex;
      return index > 0 ? this.getRankingTopWTSList[index - 1] : null;
    },
    // 与上一名进行对比
    comparisonResult() {
      const prevUserId = this.previousUserId;
      if (prevUserId !== null) {
        if (this.acindex == 3) {
          return (
            this.previousUserData.total_win - this.userinfo.total_win
          ).toFixed(2);
        } else {
          return (this.previousUserData.win - this.userinfo.win).toFixed(2);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rankingbox {
  width: 100%;
  overflow: hidden;
  .cheangebox {
    width: 2rem;
    position: fixed;
    top: 30%;
    left: 6%;
    .changeitem {
      width: 1.8rem;
      height: 0.4rem;
      background: url("../../assets/images/ranking/typebtn.png") no-repeat;
      background-size: 100% 100%;
      margin-bottom: 0.3rem;
      font-weight: 500;
      font-size: 0.24rem;
      text-align: center;
      line-height: 0.4rem;
      color: #ffffff;
    }
    .acchangeitem {
      background: url("../../assets/images/ranking/actypebtn.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .center {
    width: 10.44rem;
    margin: 0.7rem auto 0;
    .ranktitle {
      width: 10.22rem;
      height: 0.6rem;
      margin: 0 auto;
      background: url("../../assets/images/ranking/toptitle.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: bold;
      font-size: 0.5rem;
      color: #d1c1ff;
      line-height: 0.2rem;
    }

    .timebox {
      width: 8.12rem;
      height: 0.62rem;
      background: url("../../assets/images/kaijiang/timebg.png") no-repeat;
      background-size: 100% 100%;
      margin: 0.3rem auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 0.24rem;
      color: #ffffff;
      .timetext {
        font-weight: 500;
        font-size: 0.24rem;
        color: #ffffff;
      }
      .timenum {
        width: unset;
      }
      .timenum .con {
        width: unset;
        color: #fff !important;
        font-weight: 500 !important;
        font-size: 0.24rem !important;
        line-height: 0.26rem !important;
        height: 0.26rem !important;
      }
    }

    .contentbox {
      width: 100%;
      height: 5rem;
      background: rgba(2, 3, 21, 0.6);
      border: 0.01rem solid rgba(177, 155, 214, 0.6);
      position: relative;
      .tipsbox {
        position: absolute;
        right: 0;
        width: 1.1rem;
        height: 0.5rem;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.2rem;
        color: #afa4e5;
        margin-right: 0.18rem;
        img {
          width: 0.28rem;
          height: 0.28rem;
        }
        .tipstextbox {
          width: 4.2rem;
          background: rgba(8, 8, 30, 0.9);
          border-radius: 0.04rem;
          padding: 0.12rem;
          border: 0.01rem solid #5d5d9b;
          position: absolute;
          top: 0.5rem;
          right: -2.6rem;
          font-weight: 400;
          font-size: 0.18rem;
          color: #585896;
        }
        .tipstextbox::before {
          content: "";
          position: absolute;
          top: -0.12rem;
          left: 0.5rem;
          width: 0.22rem;
          height: 0.12rem;
          background-image: url("../../assets/images/about/user/jianjiao.png");
          background-size: cover;
        }
      }

      .sort-box-top {
        width: 7.76rem;
        height: 3rem;
        background-size: 100% 100%;
        display: flex;
        align-content: flex-end;
        justify-content: space-between;
        margin: 0 auto;
        .img-list {
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-bottom: 0.26rem;
          padding: 0 0.2rem;
          .img-item {
            width: 33.333%;
            // height: 1rem;
            text-align: center;
            font-size: 0.14rem;
            color: #ccc;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .header-name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 2rem;
              font-weight: 500;
              font-size: 0.2rem;
              color: #ffffff;
            }
            .header-img {
              width: 100%;
              height: 1.9rem;
              margin: 0 auto;
              // background: url(../../../assets/images/battle/paihangbang2.png)
              //   no-repeat;
              // background-size: 100% 100%;
              position: relative;
              margin-bottom: 0.14rem;

              img {
                width: 1.92rem;
                height: 100%;
                border-radius: 50%;
                overflow: hidden;
                position: absolute;
                bottom: 0rem;
                left: 10%;
              }
              .img1 {
                z-index: 9;
              }
              .img2 {
                z-index: 8;
                width: 1.1rem;
                height: 1.1rem;
                left: 27%;
                bottom: 18%;
              }
            }
            .header-img1 {
              left: 3%;
            }
            .header-img2 {
              height: 1.92rem;
            }
            .header-img3 {
              height: 1.92rem;
            }
          }
          .img-item1 {
            width: 35%;
          }
          .img-item2,
          .img-item3 {
            width: 32.5%;
            position: relative;
            bottom: -20%;
          }
        }
      }
      .sort_box_bottom {
        width: 7.76rem;
        height: 2rem;
        background: url("../../assets/images/ranking/winpodium.png") no-repeat;
        background-size: 100% 100%;
        margin: 0 auto;
      }
    }
    .infolist {
      width: 100%;
      height: 0.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .userlist {
        width: 60%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 0.2rem;
        color: #ffffff;
      }
      .numcolor {
        color: rgba(244, 194, 41, 1);
      }
      .refsbox {
        width: 1.5rem;
        height: 0.5rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .refs {
          display: flex;
          align-items: center;
          img {
            width: 0.24rem;
            margin-right: 0.04rem;
          }
          .restAni {
            animation: rotate 0.5s forwards;
          }
          .reftext {
            font-weight: 500;
            font-size: 0.2rem;
            color: #c5c0ff;
          }
        }
      }
    }
    .elselist {
      width: 100%;
      height: 8rem;
      background: rgba(2, 3, 21, 0.6);
      border: 0.01rem solid rgba(177, 155, 214, 0.6);
      overflow-y: scroll;
      padding-bottom: 0.2rem;
      margin-bottom: 0.2rem;
      .listitem {
        width: 9.8rem;
        height: 0.76rem;
        margin: 0.2rem auto 0;
        border: 0.01rem solid rgba(177, 155, 214, 0.5);
        display: flex;
        align-items: center;
        .numbox {
          width: 1.1rem;
          text-align: center;
          line-height: 0.76rem;
          font-weight: 400;
          font-size: 0.32rem;
          color: #ffffff;
        }
        .userimgbox {
          width: 1rem;
          height: 0.76rem;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 0.58rem;
            height: 0.58rem;
            border-radius: 50%;
          }
        }
        .ename {
          width: 6rem;
          font-weight: 400;
          font-size: 0.24rem;
          color: #ffffff;
          margin-left: 0.3rem;
        }
        .userjoinnum {
          font-weight: 400;
          font-size: 0.24rem;
          color: #ffffff;
        }
      }
      .e1 {
        background: rgba(33, 28, 44, 0.5);
      }
      .e2 {
        background: rgba(2, 3, 21, 0.5);
      }
    }
    /* 对于 WebKit 浏览器（如 Chrome 和 Safari） */
    .elselist::-webkit-scrollbar {
      display: none; /* 隐藏滚动条 */
    }

    /* 对于 Firefox 浏览器 */
    .elselist {
      scrollbar-width: none; /* 隐藏滚动条 */
    }

    /* 对于 IE 和 Edge 浏览器 */
    .elselist {
      -ms-overflow-style: none; /* 隐藏滚动条 */
    }
    .nulldata {
      width: 100%;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .imgbox {
        width: 1.26rem;
        height: 1.26rem;
        img {
          width: 100%;
        }
        .nulltext {
          text-align: center;
          font-size: 0.18rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #c5bbed;
        }
      }
    }
  }
}
</style>
